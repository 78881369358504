<template>
    <div class="audit_remark">
        <p v-for="item in contractSealAuditBOList" :key="item.id">
            <i
                :class="item.status == 1 ? 'toast_title' : 'toast_title delete'"
                >{{ item.status == 1 ? '通过' : '驳回' }}</i
            >
            <span
                >{{ '审批备注' }}-{{ item.adminName || '- -' }}
                <br />
                <i>{{ item.createTime || '- -' }}</i>
            </span>
            <em>{{ item.remark || '- -' }}</em>
        </p>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            contractSealAuditBOList: [],
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            console.log(data);
            if (
                data.contractSealAuditBOList &&
                data.contractSealAuditBOList.length > 0
            ) {
                this.contractSealAuditBOList = data.contractSealAuditBOList;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.audit_remark {
    padding: 0px 34px 0 20px;
    p {
        display: flex;
        font-size: 14px;
        color: #333333;
        display: flex;
        position: relative;
        margin-bottom: 16px;
        padding-left: 35px;
        span {
            color: #666666;
            display: block;
            flex: 1;
            white-space: nowrap;
            i {
                font-size: 12px;
                color: #999;
                font-style: normal;
            }
        }
        em {
            flex: 1;
            font-style: normal;
        }
    }
    .toast_title {
        display: block;
        width: 56px;
        height: 28px;
        position: absolute;
        top: -5px;
        left: -14px;
        background: rgba(35, 112, 235, 0.07);
        border-radius: 1px;
        border: 1px solid #2370eb;
        font-size: 18px;
        text-align: center;
        transform: scale(0.5);
        color: #2370eb;
        font-style: normal;
    }
    .delete {
        font-style: normal;
        left: -14px;
        background: rgba(208, 2, 27, 0.05);
        border-radius: 1px;
        border: 1px solid #d0021b;
        color: #d0021b;
        cursor: pointer;
    }
    .pay {
        font-style: normal;
        left: -14px;
        background: #02d04b0d;
        border-radius: 1px;
        border: 1px solid #50cc1d;
        color: #50cc1d;
        cursor: pointer;
    }
    p:before {
        content: '';
        position: absolute;
        left: 15px;
        top: -17px;
        height: 18px;
        border-left: 1px dashed #eff4fd;
    }
    :first-child:before {
        height: 0;
    }
}
</style>
