<template>
    <div class="declare_box">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <span>提交日期</span>
                    <el-date-picker
                        v-model="searchDate"
                        size="small"
                        type="daterange"
                        style="margin-left: 0"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        clearable
                        @change="getTableData"
                    >
                    </el-date-picker>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 220px"
                        placeholder="请输入客户名称"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData()"
                        type="primary"
                        style="margin-right: 0"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </div>
                <p>
                    <el-button
                        class="right"
                        type="primary"
                        style="margin-left: 29px"
                        size="small"
                        @click="getTableData()"
                        >查询</el-button
                    >
                    <el-button
                        class="right"
                        type="primary"
                        style="
                            margin: 0 16px 0 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        @click="emptyData"
                        >重置</el-button
                    >
                </p>
            </div>
            <div class="search-two">
                <span>审批状态</span>
                <el-radio-group v-model="auditChoice" size="small">
                    <!-- <el-radio-button
                        label="待审批"
                        @click.native.prevent="clickitem('待审批')"
                    ></el-radio-button> -->
                    <el-radio-button
                        label="已通过"
                        @click.native.prevent="clickitem('已通过')"
                    ></el-radio-button>
                    <el-radio-button
                        label="已驳回"
                        @click.native.prevent="clickitem('已驳回')"
                    ></el-radio-button>
                </el-radio-group>

                <Department
                    ref="department"
                    :departmentWidth="120"
                    :adminWidth="180"
                    :depSearch="btnP.depSearch"
                    :searchUser="btnP.userSearch"
                    @searchData="searchData"
                ></Department>

                <!-- <span v-if="btnP.depSearch">部门</span>
                <el-select
                    v-if="btnP.depSearch"
                    v-model="searchVal.departmentId"
                    size="small"
                    filterable
                    style="width: 120px"
                    clearable
                    placeholder="全部"
                    @change="changeDepartmentId"
                >
                    <el-option
                        v-for="item in departmentList"
                        :key="item.id"
                        :label="item.departmentName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <span v-if="btnP.userSearch">成员</span>
                <el-select
                    v-if="btnP.userSearch"
                    style="width: 180px"
                    v-model="searchVal.adminId"
                    size="small"
                    filterable
                    clearable
                    placeholder="全部"
                    @change="changeAdmin"
                >
                    <el-option
                        v-for="item in userName"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select> -->
            </div>
        </div>
        <div class="table-title">
            <p>
                审批列表

                <button class="add" v-if="btnP.add" @click="onAdd">
                    <i class="el-icon-plus"></i> 新增审批
                </button>
                <button class="add" v-if="btnP.down" @click="onDown">
                    <i class="iconfont icon-xiazaidaoru"></i> 模板下载
                </button>
                <span
                    v-if="auditChoice == '已驳回'"
                    style="margin-left: 16px; float: right; margin-top: 2px"
                >
                    <i
                        class="el-icon-warning-outline"
                        style="color: #feb516; font-weight: 400"
                    ></i
                    >已驳回审批，请咨询业务操作人员了解详情
                </span>
            </p>
        </div>
        <div class="table">
            <Table
                ref="table"
                @totalNum="totalNum"
                @onAudit="onAudit"
                @deleteFile="deleteFile"
            ></Table>
        </div>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增审批 -->
        <el-dialog
            :visible.sync="dialogAdd"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增审批
                </div>
            </template>
            <Add ref="add"></Add>

            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="submitAdd"
                    style="margin-right: 16px; background-color: #2370eb"
                    round
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
        <!-- 模板下载 -->
        <el-dialog
            :visible.sync="dialogDown"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    模板下载
                </div>
            </template>
            <div class="dialog_info">
                <p>邮箱</p>
                <span
                    @click="downFile('腾讯企业邮箱购买协议模板')"
                    style="margin-right: 20px; margin-bottom: 12px"
                    >腾讯企业邮箱购买协议模板
                    <i class="iconfont icon-xiazai-wenjianxiazai-18"></i>
                </span>
                <span @click="downFile('腾讯企业邮箱扩容协议模板')"
                    >腾讯企业邮箱扩容协议模板
                    <i class="iconfont icon-xiazai-wenjianxiazai-18"></i
                ></span>
                <span
                    @click="downFile('阿里企业邮箱购买协议模板')"
                    style="margin-right: 20px; margin-bottom: 12px"
                    >阿里企业邮箱购买协议模板
                    <i class="iconfont icon-xiazai-wenjianxiazai-18"></i
                ></span>
                <span @click="downFile('阿里企业邮箱扩容协议模板')"
                    >阿里企业邮箱扩容协议模板
                    <i class="iconfont icon-xiazai-wenjianxiazai-18"></i
                ></span>
                <span
                    @click="downFile('网易企业邮箱模板')"
                    style="margin-right: 20px; margin-bottom: 12px"
                    >网易企业邮箱模板
                    <i class="iconfont icon-xiazai-wenjianxiazai-18"></i
                ></span>
                <p>微盘</p>
                <span
                    @click="downFile('微盘购买合同模板')"
                    style="margin-right: 20px; margin-bottom: 12px"
                    >微盘购买合同模板
                    <i class="iconfont icon-xiazai-wenjianxiazai-18"></i>
                </span>
                <p>域名</p>
                <span
                    @click="downFile('域名购买合同模板')"
                    style="margin-right: 20px; margin-bottom: 12px"
                    >域名购买合同模板
                    <i class="iconfont icon-xiazai-wenjianxiazai-18"></i>
                </span>
                <p>会话存档</p>
                <span
                    @click="downFile('会话存档合同模板')"
                    style="margin-right: 20px; margin-bottom: 12px"
                    >会话存档合同模板
                    <i class="iconfont icon-xiazai-wenjianxiazai-18"></i>
                </span>
            </div>
        </el-dialog>
        <!-- 审批 -->
        <el-drawer
            :visible.sync="drawerAudit"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    合同盖章审批
                </div>
            </template>
            <Audit @close="handleClose" ref="audit" />
        </el-drawer>
    </div>
</template>

<script>
import { sealAdd, sealGet, sealUpdate } from '@/api/seal/seal.js';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import Table from '../table/table.vue';
import Add from '../add/add.vue';
import Audit from '../audit/audit.vue';
import Department from '../../../../components/Department';
export default {
    name: '',
    props: {},
    data() {
        return {
            searchVal: {
                adminId: '',
            },
            btnP: {},
            tag: true,
            searchDate: [],
            auditChoice: '已通过',
            currentPage: 1,
            pagesize: 20,
            total: 0,
            direction: 'rtl',
            dialogAdd: false,
            baseUrl: this.$url.templateUrl() + '/contract/model/',
            dialogDown: false,
            drawerAudit: false,
            tableRow: {},
            userName: [],
            departmentList: [],
        };
    },
    components: {
        Table,
        Add,
        Audit,
        Department,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取部门成员
        getUserList(departmentId) {
            this.searchVal.adminId = '';
            let data = {
                param: {},
            };
            if (!departmentId) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.departmentId = departmentId;
            }
            roleList(data).then((res) => {
                this.userName = res.data;
            });
        },
        totalNum(data) {
            this.total = data;
        },
        deleteFile(row) {
            this.$confirm(
                '是否确认删除所选的电子合同？删除后数据不可恢复',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    let data = {
                        param: {
                            id: row.id,
                            status: 5,
                        },
                    };
                    // console.log(data);
                    sealUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.getData();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.departmentList = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            // console.log(btnArr);
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            if (btn.depSearch) {
                this.getDepartmentList();
            }
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            this.getData();
        },
        getData() {
            setTimeout(() => {
                this.$refs.table.getData(
                    this.searchVal,
                    this.currentPage,
                    this.pagesize,
                    this.searchDate,
                    this.auditChoice,
                    this.btnP
                );
            }, 0);
        },
        getTableData() {
            this.currentPage = 1;
            this.pagesize = 20;
            this.getData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 申报状态
        clickitem(e) {
            this.auditChoice = e;
            this.currentPage = 1;
            this.pagesize = 20;
            this.getData();
        },
        onAdd() {
            this.dialogAdd = true;
            setTimeout(() => {
                this.$refs.add.getData();
            }, 0);
        },
        submitAdd() {
            let sealData = this.$refs.add.form;
            if (!sealData.customerId) {
                return this.$message.error('请选择客户');
            }
            if (!sealData.signCompany) {
                return this.$message.error('请选择签署公司');
            }
            if (!sealData.originalAttachmentUrl) {
                return this.$message.error('请重新上传文件');
            }
            let data = {
                param: {
                    ...sealData,
                },
            };
            sealAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        onDown() {
            this.dialogDown = true;
        },
        downFile(str) {
            window.open(this.baseUrl + str + '.docx');
        },
        onAudit(row) {
            this.tableRow = row;
            this.drawerAudit = true;
            this.sealGet(row.id);
        },
        sealGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            sealGet(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerAudit) {
                        this.$refs.audit.getData(res.data, this.btnP);
                    }
                }
            });
        },
        // 时间输入框变化
        handleClose() {
            this.dialogAdd = false;
            this.dialogDown = false;
            this.drawerAudit = false;
            this.getData();
            this.tableRow = {};
        },
        // 重置
        emptyData() {
            this.tableRow = {};
            this.searchVal = {
                adminId: '',
                field: 1,
            };
            this.searchDate = [];
            this.currentPage = 1;
            this.pagesize = 20;
            this.getData();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.declare_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                    margin-right: 16px;
                }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .table-title {
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
            float: right;
        }
        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
        }

        //    button:hover {
        //        background: #2370eb;
        //        color: #ffffff;
        //        border-color: #2370eb;
        //    }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
    .table {
        flex: 1;
    }
    .search-two {
        margin-bottom: 10px;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .el-icon-sort-down {
        transform: rotateX(180deg) rotateZ(270deg);
        margin-left: 5px;
        color: #c0c4cc;
        vertical-align: middle;
    }
    .money_input {
        display: inline-block;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        /deep/ .el-input__inner {
            border: none;
            padding: 0 15px;
        }
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
.dialog_info {
    width: 85%;
    margin: 25px auto 0;
    padding-bottom: 50px;
    font-size: 14px;
    color: #333333;

    p {
        margin-bottom: 16px;
        font-weight: 600;
    }
    span {
        display: inline-block;
        width: 215px;
        height: 48px;
        background: #f9fafc;
        border-radius: 8px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        i {
            color: #2370eb;
        }
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
/deep/ .el-dialog__footer {
    padding: 0;
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e5e5e5;
}
// 处理input type = number的上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/deep/ input[type='number'] {
    -moz-appearance: textfield;
}
</style>
