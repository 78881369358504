<template>
    <div class="add_box">
        <el-form
            label-position="left"
            style="margin: 0 auto"
            size="small"
            label-width="90px"
        >
            <el-form-item label="客户" required>
                <el-select
                    remote
                    :remote-method="remoteMethod"
                    v-model="form.customerId"
                    size="small"
                    filterable
                    style="width: 270px"
                    clearable
                    placeholder="请选择或输入关键字搜索"
                >
                    <el-option
                        v-for="item in Relation"
                        :key="item.id"
                        :label="item.companyName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="签署公司" required>
                <el-select
                    v-model="form.signCompany"
                    style="width: 270px; margin-right: 27px"
                    clearable
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in $tableDataHandle.selectTypeB()"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        v-if="item.value != 9"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="上传附件" required>
                <el-upload
                    :action="action"
                    class="avatar-uploader"
                    list-type="picture-card"
                    :on-success="onBusinessLicense"
                    :before-upload="beforeAvatarUploads"
                    multiple
                    drag
                    ref="businessLicense"
                    accept=".pdf"
                    :file-list="businessLicense"
                >
                    <i slot="default" class="el-icon-plus"></i>
                    <div
                        slot="file"
                        slot-scope="{ file }"
                        style="text-align: center"
                    >
                        <span>
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                            >
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span
                                class="el-upload-list__item-delete"
                                @click="handleRemove(file)"
                            >
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </div>
                </el-upload>
                <p style="color: #999; font-size: 12px">（仅支持pdf格式）</p>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { customerList } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {},
            hideUpload: false,
            businessLicense: [],
            action: this.$url.url() + '/file/local/upload/contract',
            Relation: [],
        };
    },
    components: {},
    created() {},
    methods: {
        getData() {
            this.form = {};
            this.getRelation();
            this.businessLicense = [];
        },
        remoteMethod(query) {
            this.getRelation(query);
        },
        getRelation(name) {
            let data = {
                param: {},
                pageNum: 1,
                pageSize: 20,
            };
            data.param.adminId = sessionStorage.getItem('adminId');
            if (name) {
                data.param.name = name;
            }
            customerList(data).then((res) => {
                this.Relation = res.data.list;
            });
        },
        // 上传
        onBusinessLicense(response) {
            if ((response.code = 200)) {
                this.form.originalAttachmentUrl = response.data;
                this.hideUpload = true;
                this.$message.success('附件上传成功');
            } else {
                this.$message.success('附件上传失败');
            }
        },
        // 校验
        beforeAvatarUploads(file) {
            if (this.form.originalAttachmentUrl) {
                this.$message.error('仅上传一个附件');
                return false;
            }
            if (file.type == 'application/pdf') {
                this.form.originalAttachmentName = file.name;
                return true;
            } else {
                this.$message.error('请上传pdf格式的文件');
                return false;
            }
        },
        // 预览
        handlePictureCardPreview(file) {
            window.open(file.response.data, '_blank');
        },
        // 删除
        handleRemove(file) {
            this.$refs['businessLicense'].uploadFiles = [];
            this.form.originalAttachmentUrl = '';
            this.form.originalAttachmentName = '';
            this.hideUpload = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.add_box {
    width: 80%;
    margin: 25px auto 80px;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 65px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
    /deep/ .avatar-uploader .el-upload {
        border: none;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: #f3f6fb;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    /deep/.el-upload-dragger {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    /deep/ .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    /deep/.avatar-uploader-icon {
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 80px;
        text-align: center;
    }
    /deep/ .el-upload-list__item.is-success {
        width: 100px !important;
        height: 100px !important;
    }
}
</style>
