import http from '@/utils/http.js'

// 合同盖章添加
export function sealAdd(data) {
     return http({
          url: "/jasoboss/contract/seal/add",
          method: "post",
          data
     })
}
// 合同盖章修改
export function sealUpdate(data) {
     return http({
          url: "/jasoboss/contract/seal/update",
          method: "post",
          data
     })
}
// 合同盖章列表
export function sealList(data) {
     return http({
          url: "/jasoboss/contract/seal/list",
          method: "post",
          data,

     })
}
// 合同盖章单个
export function sealGet(data) {
     return http({
          url: "/jasoboss/contract/seal/get",
          method: "post",
          data
     })
}
// 合同盖章审批
export function auditSeal(data) {
     return http({
          url: "/jasoboss/contract/seal/audit/add",
          method: "post",
          data
     })
}