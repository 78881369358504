<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            key="1"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column label="提交日期" width="93" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.createTime
                            ? scope.row.createTime.slice(0, 10)
                            : '- -' || '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="编号" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.contractSealNo
                            ? scope.row.contractSealNo
                            : '- -' || '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="客户" show-overflow-tooltip width="239">
                <template slot-scope="scope">
                    <span>{{ scope.row.companyName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="签署公司" show-overflow-tooltip width="239">
                <template slot-scope="scope">
                    <span>{{
                        $tableDataHandle.echoTypeB(scope.row.signCompany)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="原始附件" show-overflow-tooltip width="240">
                <template slot="header">
                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;原始附件
                    </div>
                </template>
                <template slot-scope="scope">
                    <div class="info_box">
                        <a
                            :href="scope.row.originalAttachmentUrl"
                            :download="scope.row.originalAttachmentName"
                        >
                            <i
                                class="iconfont icon-xiazai-wenjianxiazai-18"
                                v-if="btnP.originalDown"
                            ></i>
                        </a>
                        <span
                            class="info"
                            v-if="btnP.originalDetail"
                            @click="openFile(scope.row.originalAttachmentUrl)"
                            >{{
                                scope.row.originalAttachmentName || '- -'
                            }}</span
                        >
                        <span v-else>{{
                            scope.row.originalAttachmentName || '- -'
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="盖章附件"
                show-overflow-tooltip
                width="120"
                v-if="auditChoice == '已通过'"
            >
                <template slot-scope="scope">
                    <div>
                        <span
                            style="
                                color: #2370eb;
                                cursor: pointer;
                                margin-right: 8px;
                            "
                        >
                            <a
                                :href="scope.row.sealAttachmentUrl"
                                :download="scope.row.sealAttachmentName"
                                v-if="btnP.originalDown"
                                style="text-decoration: none; color: #2370eb"
                            >
                                下载
                            </a></span
                        >
                        <span
                            style="
                                color: #2370eb;
                                cursor: pointer;
                                margin-right: 8px;
                            "
                            v-if="btnP.sealDetail"
                            @click="openFile(scope.row.sealAttachmentUrl)"
                            >预览</span
                        >
                        <span v-else>- -</span>
                        <span
                            style="color: red; cursor: pointer"
                            v-if="scope.row.sealAttachmentUrl"
                            @click="deleteFile(scope.row)"
                            >删除</span
                        >
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20" v-if="auditChoice == '已通过'">
            </el-table-column>
            <el-table-column label="审批状态" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1
                            ? '待审批'
                            : scope.row.status == 3
                            ? '已通过'
                            : scope.row.status == 4
                            ? '已驳回'
                            : '- -' || '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="所属部门" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-tooltip placement="top">
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <span class="tetx">
                            {{ returnName(scope.row) }}
                        </span>
                    </el-tooltip>
                    <!-- <span>{{ scope.row.departmentName || '- -' }}</span> -->
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="所属人" show-overflow-tooltip width="70">
                <template slot-scope="scope">
                    <span>{{ scope.row.adminName || '- -' }}</span>
                </template>
            </el-table-column>

            <!-- <el-table-column label="审批备注" width="173" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" placement="top">
                        <div slot="content">
                            <p
                                class="tooltip_box"
                                v-for="item in scope.row.contractSealAuditBOList"
                                :key="item.id"
                            >
                                <span> {{ item.adminName + '-' + item.createTime }}: </span>
                                <em>
                                    {{ item.remark || '- -' }}
                                </em>
                            </p>
                        </div>
                        <span
                            v-if="
                                scope.row.contractSealAuditBOList &&
                                scope.row.contractSealAuditBOList.length > 0
                            "
                            >{{
                                scope.row.contractSealAuditBOList[
                                    scope.row.contractSealAuditBOList.length - 1
                                ].remark
                            }}</span
                        >
                        <span v-else>- -</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column> -->
            <!-- <el-table-column label="操作" fixed="right" width="80px" v-if="btnP.opt">
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.audit && scope.row.status == 1"
                        style="color: #2370eb"
                        type="text"
                        @click="onAudit(scope.row)"
                    >
                        审批
                    </el-button>
                </template>
            </el-table-column> -->
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
    <!-- <div class="table-box" v-else>
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            key="2"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column label="提交日期" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.createTime
                            ? scope.row.createTime.slice(0, 10)
                            : '- -' || '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="编号" show-overflow-tooltip width="120">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.contractSealNo
                            ? scope.row.contractSealNo
                            : '- -' || '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="客户" show-overflow-tooltip width="239">
                <template slot-scope="scope">
                    <span>{{ scope.row.companyName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="签署公司" show-overflow-tooltip width="239">
                <template slot-scope="scope">
                    <span>{{
                        $tableDataHandle.echoTypeB(scope.row.signCompany) ||
                        '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="原始附件" show-overflow-tooltip width="240">
                <template slot="header">
                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;原始附件
                    </div>
                </template>
                <template slot-scope="scope">
                    <div class="info_box">
                        <a
                            :href="scope.row.originalAttachmentUrl"
                            :download="scope.row.originalAttachmentName"
                        >
                            <i
                                class="iconfont icon-xiazai-wenjianxiazai-18"
                                v-if="btnP.originalDown"
                            ></i>
                        </a>
                        <span
                            class="info"
                            v-if="btnP.originalDetail"
                            @click="openFile(scope.row.originalAttachmentUrl)"
                            >{{
                                scope.row.originalAttachmentName || '- -'
                            }}</span
                        >
                        <span v-else>{{
                            scope.row.originalAttachmentName || '- -'
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="审批备注" show-overflow-tooltip width="173">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" placement="top">
                        <div slot="content">
                            <p
                                class="tooltip_box"
                                v-for="item in scope.row
                                    .contractSealAuditBOList"
                                :key="item.id"
                            >
                                <span>
                                    {{
                                        item.adminName + '-' + item.createTime
                                    }}:
                                </span>
                                <em>
                                    {{ item.remark || '- -' }}
                                </em>
                            </p>
                        </div>
                        <span
                            v-if="
                                scope.row.contractSealAuditBOList &&
                                scope.row.contractSealAuditBOList.length > 0
                            "
                            >{{
                                scope.row.contractSealAuditBOList[
                                    scope.row.contractSealAuditBOList.length - 1
                                ].remark
                            }}</span
                        >
                        <span v-else>- -</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="审批状态" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1
                            ? '待审批'
                            : scope.row.status == 3
                            ? '已通过'
                            : scope.row.status == 4
                            ? '已驳回'
                            : '- -' || '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <div v-show="btnP.depSearch">
                <el-table-column
                    label="所属部门"
                    width="80"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.departmentName || '- -' }}</span>
                    </template>
                </el-table-column>
            </div>
            <el-table-column min-width="20"> </el-table-column>
            <div v-show="btnP.userSearch || btnP.depSearch">
                <el-table-column
                    label="所属人"
                    width="55"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.adminName || '- -' }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
            </div>
           <el-table-column label="操作" fixed="right" width="80px" v-if="btnP.opt">
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.audit && scope.row.status == 1"
                        style="color: #2370eb"
                        type="text"
                        @click="onAudit(scope.row)"
                    >
                        审批
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div> -->
</template>
<script>
import { sealList, sealGet, sealUpdate } from '@/api/seal/seal.js';

export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            auditChoice: '',
            templateUrl: this.$url.templateUrl(),
            baseUrl: this.$url.templateUrl() + '/contract/model/',
        };
    },
    methods: {
        // 获取列表
        getData(
            searchVal,
            currentPage,
            pagesize,
            searchDate,
            auditChoice,
            btnP
        ) {
            this.auditChoice = auditChoice;
            this.btnP = btnP;
            let data = {
                param: {},
                pageNum: currentPage,
                pageSize: pagesize,
            };
            if (btnP.userSearch && !btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');

                if (searchVal.adminId) {
                    data.param.adminId = searchVal.adminId;
                }
            }

            if (btnP.depSearch) {
                if (searchVal.adminId) {
                    data.param.adminId = searchVal.adminId;
                }
                if (searchVal.departmentId) {
                    data.param.departmentId = searchVal.departmentId;
                }
            }
            if (auditChoice == '待审批') {
                data.param.status = 1;
            }
            if (auditChoice == '已通过') {
                data.param.status = 3;
            }
            if (auditChoice == '已驳回') {
                data.param.status = 4;
            }
            if (!btnP.userSearch && !btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (searchDate && searchDate.length > 0) {
                data.param.startCreateDate = searchDate[0];
                data.param.endCreateDate = searchDate[1];
            }
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            sealList(data).then((res) => {
                if (res.code == 200) {
                    setTimeout(() => {
                        this.tableData = res.data.list;
                    }, 0);
                    this.$emit('totalNum', res.data.total);
                }
            });
        },
        onAudit(data) {
            this.$emit('onAudit', data);
        },
        openFile(file) {
            window.open(file, '_blank');
        },
        openDown(file) {
            if (file.indexOf('.doc') != -1 || file.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + file
                );
            } else {
                window.open(file, '_blank');
            }
        },
        deleteFile(row) {
            this.$emit('deleteFile', row);
        },
        sealGet(id) {
            sealGet(data).then((res) => {
                if (res.code == 200) {
                }
            });
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;

    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    .el-table {
        /deep/tr td:nth-child(1) .cell,
        /deep/tr td:nth-child(6) .cell {
            padding-right: 20px;
            position: relative;
            .mini_circle {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 16px;
                background: #ff9385;
                border-radius: 63px 63px 63px 1px;
                font-size: 12px;
                font-style: normal;
                text-align: center;
                line-height: 16px;
                color: #fff;
            }
        }
    }
}
.info {
    color: #2370eb;
    cursor: pointer;
    display: block;
    overflow: hidden;
}
.info_box {
    position: relative;

    overflow: hidden;
    display: flex;
}
.left_toast {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 12px;
    line-height: 1.2;
}
.tooltip_box {
    display: flex;
    span {
        display: block;
        width: 180px;
    }
    em {
        flex: 1;
        word-break: break-all;
        font-style: normal;
    }
}
.tooltip_box + .tooltip_box {
    margin-top: 10px;
}
.sale_order {
    display: flex;
    align-items: center;
    p {
        color: #2370eb;
    }
    div {
        color: #333;
    }
    i {
        cursor: pointer;
        margin-left: 10px;
        color: #2370eb;
    }
}
.icon-xiazai-wenjianxiazai-18 {
    color: #2370eb;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
}
</style>
<style lang="scss"></style>
