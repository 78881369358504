<template>
    <div class="audit_box">
        <div style="flex: 1; overflow-y: auto">
            <p class="detail_title" style="color: #2370eb">
                {{ sealDetail.adminName }}提交的合同盖章申请
            </p>
            <SealInfo ref="sealInfo"></SealInfo>
            <p
                class="detail_title"
                style="color: #50cc1d"
                v-if="
                    sealDetail.contractSealAuditBOList &&
                    sealDetail.contractSealAuditBOList.length > 0
                "
            >
                审批备注
            </p>
            <AuditRemark
                ref="record"
                v-if="
                    sealDetail.contractSealAuditBOList &&
                    sealDetail.contractSealAuditBOList.length > 0
                "
            ></AuditRemark>
            <div class="bgc"></div>
            <p class="detail_title" style="color: #50cc1d">审批</p>
            <el-form label-position="left" label-width="80px" size="small">
                <el-form-item label="审批结果" required>
                    <el-radio-group v-model="auditData.status">
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="0">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="审批备注">
                    <el-input
                        style="width: 270px"
                        type="textarea"
                        rows="3"
                        placeholder="请输入审批备注"
                        v-model="auditData.remark"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label="选择公章" required v-if="btnP.official">
                    <el-radio-group v-model="auditData.sealCompany">
                        <el-radio :label="1">伽搜</el-radio>
                        <el-radio :label="2">伽湾</el-radio>
                        <el-radio :label="3">钱邮</el-radio>
                    </el-radio-group>
                </el-form-item> -->
            </el-form>
        </div>
        <div slot="footer" class="bottom-but">
            <el-button
                @click="onClose"
                round
                style="
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                size="mini"
                >取 消</el-button
            >
            <el-button
                type="primary"
                style="
                    margin-right: 15px;
                    background: #2370eb;
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                round
                size="mini"
                @click="sealAudit"
                >确 定</el-button
            >
        </div>
    </div>
</template>

<script>
import SealInfo from './components/sealInfo.vue';
import AuditRemark from './components/record.vue';
import { auditSeal } from '@/api/seal/seal.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            sealDetail: {},
            auditData: {},
            btnP: {},
        };
    },
    watch: {},
    components: {
        SealInfo,
        AuditRemark,
    },
    created() {},
    methods: {
        getData(data, btnP) {
            this.auditData = {};
            this.sealDetail = data;
            this.btnP = btnP;
            setTimeout(() => {
                this.$refs.sealInfo.getData(data);
                if (
                    data.contractSealAuditBOList &&
                    data.contractSealAuditBOList.length > 0
                ) {
                    this.$refs.record.getData(data);
                }
            }, 0);
        },

        //   审批
        sealAudit() {
            if (this.auditData.status === '' || this.auditData.status == null) {
                this.$message.error('请选择审批结果');
                return;
            }

            // if (!this.auditData.sealCompany && this.btnP.official) {
            //     this.$message.error('请选择公章');
            //     return;
            // }
            let data = {
                param: {
                    sealId: this.sealDetail.id,
                    remark: this.auditData.remark,
                    status: this.auditData.status,
                    // sealCompany: this.auditData.sealCompany,
                },
            };
            auditSeal(data).then((res) => {
                if (res.code == 200 && res.data) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
                // if (!res.data) {
                //     this.$message.success('公章类型选择错误，请重新勾选');
                // }
            });
        },
        onClose() {
            this.auditData = {};
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.audit_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    > div {
        // padding: 14px 34px 0 20px;
        font-size: 14px;
    }
    .el-form {
        padding: 0px 34px 0 20px;
    }
    .detail_title {
        margin-bottom: 16px;
        padding-top: 14px;
        padding-left: 20px;
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        padding: 0;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
    .bgc {
        height: 9px;
        background: #f9f9f9;
    }
}
</style>
