<template>
    <div class="seal_info">
        <div>
            <p>
                <span>所在部门</span>
                <em>
                    {{ sealInfo.departmentName || '- -' }}
                </em>
            </p>
        </div>
        <div>
            <p>
                <span>提交时间</span
                ><em>{{ $tableDataHandle.createTime(sealInfo) }}</em>
            </p>
        </div>
        <div>
            <p>
                <span>客户</span><em>{{ sealInfo.companyName || '- -' }}</em>
            </p>
        </div>

        <div>
            <p>
                <span>审批状态</span
                ><em>{{
                    sealInfo.status == 1 || sealInfo.status == 2
                        ? '待审批'
                        : sealInfo.status == 3
                        ? '已通过'
                        : sealInfo.status == 4
                        ? '已驳回'
                        : '- -'
                }}</em>
            </p>
        </div>
        <div>
            <p>
                <span>原始附件</span
                ><em
                    style="color: #2370eb; cursor: pointer"
                    @click="openFile"
                    >{{ sealInfo.originalAttachmentName || '- -' }}</em
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: '',

    data() {
        return {
            sealInfo: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.sealInfo = data;
        },
        openFile() {
            window.open(this.sealInfo.originalAttachmentUrl, '_blank');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.seal_info {
    padding: 0px 34px 0 20px;
    div {
        margin-bottom: 16px;
        display: flex;
        font-size: 14px;

        p {
            flex: 1;
            color: #333333;
            display: flex;
            span {
                color: #666666;
                display: block;
                width: 56px;
                white-space: nowrap;
                margin-right: 12px;
            }
            em {
                font-weight: 600;
                font-style: normal;
                flex: 1;
                i {
                    font-size: 16px;
                    color: #2370eb;
                    cursor: pointer;
                    vertical-align: -1px;
                    margin-left: 10px;
                }
            }
            .el-select {
                margin-top: -5px;
                margin-bottom: 0;
            }
            button {
                height: 25px;
                line-height: 0px;
                padding: 5px 12px;
                margin-left: 5px;
            }

            button + button {
                margin-left: 5px;
            }
        }
    }
    .htbd {
        display: table;
        p + p {
            margin-top: 5px;
        }
    }
}
</style>
